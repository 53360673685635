// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { createApp, h } from "vue/dist/vue.esm-bundler.js";
import { paramCase } from "change-case";
import { defineAsyncComponent } from "vue";
import { createLogger } from "vue-logger-plugin";
import { clickOutside } from "@/core/click-outside.directive";

import store from "@/store";
import { plugin, defaultConfig } from "@formkit/vue";
import FloatingVue from "floating-vue";
import { cs } from "@formkit/i18n";

import * as Sentry from "@sentry/vue";

declare global {
  interface Window {
    postId: any;
    selectedType: any;
    selectedPackage: any;
    brandTabIndex: any;
    environment: string;
    gtag: (...args: any[]) => void;
    dataLayer: any[];
  }
}

const components: any = {};
components["header"] = defineAsyncComponent(() => import("@/components/header"));
components["footer"] = defineAsyncComponent(() => import("@/components/footer"));
components["call-bubble"] = defineAsyncComponent(() => import("@/components/call-bubble"));
components["csat-form"] = defineAsyncComponent(() => import("@/components/csat-form"));

components["map-contacts"] = defineAsyncComponent(() => import("@/components/map-contacts"));
components["advantages-cats"] = defineAsyncComponent(() => import("@/components/advantages-cats"));
components["cta-footer"] = defineAsyncComponent(() => import("@/components/cta-footer"));
components["main-city-banner"] = defineAsyncComponent(() => import("@/components/main-city-banner"));
components["main-tariffs"] = defineAsyncComponent(() => import("@/components/main-tariffs"));
components["mobile-tariffs"] = defineAsyncComponent(() => import("@/components/mobile-tariffs"));
components["tv"] = defineAsyncComponent(() => import("@/components/tv"));
components["tv-cloudflare"] = defineAsyncComponent(() => import("@/components/tv-cloudflare"));

components["tariffs"] = defineAsyncComponent(() => import("@/components/tariffs"));
components["news"] = defineAsyncComponent(() => import("@/components/news"));
components["5e-banner"] = defineAsyncComponent(() => import("@/components/5e-banner"));
components["technology-block"] = defineAsyncComponent(() => import("@/components/technology-block"));
components["device-gallery"] = defineAsyncComponent(() => import("@/components/device-gallery"));
components["documents"] = defineAsyncComponent(() => import("@/components/documents"));
components["documents-archive"] = defineAsyncComponent(() => import("@/components/documents-archive"));
components["contacts"] = defineAsyncComponent(() => import("@/components/contacts"));
components["support"] = defineAsyncComponent(() => import("@/components/support"));
components["careers"] = defineAsyncComponent(() => import("@/components/careers"));
components["careers-department"] = defineAsyncComponent(() => import("@/components/careers-department"));
components["careers-vacancy"] = defineAsyncComponent(() => import("@/components/careers-vacancy"));
components["chart"] = defineAsyncComponent(() => import("@/components/chart"));
components["autosuggest"] = defineAsyncComponent(() => import("@/components/autosuggest"));
components["faq"] = defineAsyncComponent(() => import("@//components/faq"));
components["contact-form"] = defineAsyncComponent(() => import("@/components/contact-form"));
components["manager-block"] = defineAsyncComponent(() => import("@/components/manager-block"));
components["careers-positions"] = defineAsyncComponent(() => import("@/components/careers-positions"));
components["tv-channels"] = defineAsyncComponent(() => import("@/components/tv-channels"));
components["search-address"] = defineAsyncComponent(() => import("@/components/search-address"));
components["tv-scroll"] = defineAsyncComponent(() => import("@/components/tv-scroll"));
components["cta-image"] = defineAsyncComponent(() => import("@/components/cta-image"));
components["connection-steps"] = defineAsyncComponent(() => import("@/components/connection-steps"));
components["popups"] = defineAsyncComponent(() => import("@/components/popups"));
components["gradient-block"] = defineAsyncComponent(() => import("@/components/gradient-block"));

components["tv-channels-brand"] = defineAsyncComponent(() => import("@/components/tv-channels-brand"));
components["small-phone-form"] = defineAsyncComponent(() => import("@/components/small-phone"));
components["reviews-block"] = defineAsyncComponent(() => import("@/components/reviews-block"));
components["gpon-banner"] = defineAsyncComponent(() => import("@/components/gpon-banner"));
components["gallery-block"] = defineAsyncComponent(() => import("@/components/gallery-block"));
components["device-block"] = defineAsyncComponent(() => import("@/components/device-block"));
components["search"] = defineAsyncComponent(() => import("@/components/search"));
components["advantages-card-block"] = defineAsyncComponent(() => import("@/components/advantages-card-block"));
components["contact-form-b2b"] = defineAsyncComponent(() => import("@/components/contact-form-b2b"));
components["icons-block"] = defineAsyncComponent(() => import("@/components/icons-block"));
components["video-block"] = defineAsyncComponent(() => import("@/components/video-block"));
components["address-block"] = defineAsyncComponent(() => import("@/components/address-block"));

components["cookie-banner"] = defineAsyncComponent(() => import("@/components/cookie-banner"));
components["sim-tariffs-wrapper"] = defineAsyncComponent(() => import("@/components/sim-tariffs-wrapper"));
components["sim-iptv-tariffs-wrapper"] = defineAsyncComponent(() => import("@/components/sim-iptv-tariffs-wrapper"));
components["generic-form"] = defineAsyncComponent(() => import("@/components/generic-form"));

components["referral-form"] = defineAsyncComponent(() => import("@/components/referral-form"));
components["referral-report-form"] = defineAsyncComponent(() => import("@/components/referral-report-form"));

components["iptv-email"] = defineAsyncComponent(() => import("@/components/iptv-email"));
components["iptv-step-2"] = defineAsyncComponent(() => import("@/components/iptv-step-2"));

components["modular-exit-intent"] = defineAsyncComponent(() => import("@/components/modular-exit-intent.vue"));

components["showcase-slider"] = defineAsyncComponent(() => import("@/components/showcase-slider"));

const Logger = createLogger({
  enabled: true,
  level: "debug",
});

const createdApps: any[] = [];
const phoneInputWhitelist = ["modular-exit-intent"];

function initApp(components: any[]) {
  document.querySelectorAll("[vue-component]").forEach((element: any) => {
    const componentName: any = paramCase(element.getAttribute("vue-component"));
    if (components[componentName]) {
      components[componentName].__asyncLoader().then((a: any) => {
        const app = createApp(a, { ...element.dataset })
          .use(Logger)
          .use(
            plugin,
            defaultConfig({
              locales: { cs },
              locale: "cs",
            })
          )
          .use(store.original)
          .use(FloatingVue, {
            themes: {
              tooltip: {
                triggers: ["hover", "focus", "touch", "click"],
              },
            },
          })
          .directive("click-outside", clickOutside)
          .component(
            "phone-country-code",
            defineAsyncComponent(() => import("@/components/phone-block-country-code"))
          );

        if (phoneInputWhitelist.includes(componentName)) {
          app.component(
            "PhoneInput",
            defineAsyncComponent(() => import("@/components/phone-input.vue"))
          );
        }

        createdApps.push({ el: element, app: app });

        app.mount(element);
      });
    } else {
      console.warn("Missing define vue components. Component name: " + componentName);
    }
  });
}

initApp(components);

if (window.environment !== "localhost") {
  Sentry.init({
    app: createdApps,
    dsn: "https://66f108cd2678dcf68b9ecbb81dd09f1c@o4507808627621888.ingest.de.sentry.io/4507808634962000",
    environment: window.environment,
    allowUrls: ["poda.cz"],
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    tracePropagationTargets: ["localhost"],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
