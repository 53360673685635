import * as Sentry from "@sentry/vue";

interface HolidaysData {
  lastUpdated: string;
  holidays: string[];
}

export class HolidaysService {
  protected url = "/wp-json/holidays/list";

  protected dataKey = "holidays.data";
  protected checkedAtKey = "holidays.checkedAt";

  protected textEncoder = new TextEncoder();
  protected textDecoder = new TextDecoder();

  protected activeRequests = new Set();
  protected requestPromises = new Map();
  protected requestCache = new Map();

  protected data: HolidaysData;
  protected holidaysCheckedAt: string | null;

  constructor() {
    try {
      const sessionData = localStorage.getItem(this.dataKey);

      if (sessionData) {
        this.data = JSON.parse(new TextDecoder().decode(Uint8Array.from(atob(sessionData), (c) => c.charCodeAt(0))));
      }
    } catch (error) {
      console.error("Error occurred: ", error);
      Sentry.captureException(error);
    }
    this.holidaysCheckedAt = localStorage.getItem(this.checkedAtKey);
  }

  public async getHolidays(): Promise<HolidaysData> {
    if (
      !this.data ||
      !this.holidaysCheckedAt ||
      Date.now() - Number(this.holidaysCheckedAt) > 1000 * 60 * 60 * 24 * 30
    ) {
      try {
        await this.request();
      } catch (error) {
        Sentry.captureException(error);
      }
    }

    return this.data;
  }

  protected async request(): Promise<Response> {
    if (this.requestCache.has(this.url)) {
      return Promise.resolve(this.requestCache.get(this.url));
    }

    if (this.activeRequests.has(this.url)) {
      return this.requestPromises.get(this.url);
    }

    this.activeRequests.add(this.url);

    const requestPromise = fetch(this.url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Request failed with status ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        const currentDate = Date.now().toString();

        this.data = data;
        this.holidaysCheckedAt = currentDate;

        localStorage.setItem(
          this.dataKey,
          btoa(
            new TextEncoder()
              .encode(JSON.stringify(this.data))
              .reduce((data, byte) => data + String.fromCharCode(byte), "")
          )
        );

        localStorage.setItem(this.checkedAtKey, this.holidaysCheckedAt);

        this.requestCache.set(this.url, data);
        this.activeRequests.delete(this.url);
        this.requestPromises.delete(this.url);
        return data;
      })
      .catch((error: Error) => {
        console.error("Error occurred: ", error);
        Sentry.captureException(error);
        this.activeRequests.delete(this.url);
        this.requestPromises.delete(this.url);
        throw error;
      });

    this.requestPromises.set(this.url, requestPromise);

    return requestPromise;
  }

  async isClientCenterOpen() {
    await this.getHolidays();

    const currentTime = new Date();
    let isOpen = false;

    if (
      [1, 2, 3, 4, 5].indexOf(currentTime.getDay()) !== -1 &&
      !this.isHoliday() &&
      currentTime.getHours() >= 7 &&
      (currentTime.getHours() < 16 || (currentTime.getHours() == 16 && currentTime.getMinutes() < 30))
    ) {
      isOpen = true;
    }

    return isOpen;
  }

  isHoliday() {
    const currentTime = new Date();

    let isHoliday = false;

    for (let i = 0; i < this.data.holidays.length; i++) {
      const holidayDate = new Date(this.data.holidays[i]);

      if (
        holidayDate.getFullYear() === currentTime.getFullYear() &&
        holidayDate.getMonth() === currentTime.getMonth() &&
        holidayDate.getDate() === currentTime.getDate()
      ) {
        isHoliday = true;
        break;
      }
    }

    return isHoliday;
  }
}
